// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("trix")
require("@rails/actiontext")

import "bootstrap"
import "../stylesheets/application"
import "controllers"
import flatpickr from "flatpickr"
require("flatpickr/dist/flatpickr.css")

import {install} from '@github/hotkey'

document.addEventListener("turbolinks:load", () => {
  $("[data-toggle='tooltip']").tooltip()
  $("[data-toggle='popover']").popover()

  $(".notice, .alert").delay(5000).slideUp(500, function() {
    $(".notice, .alert").alert("close");
  });

  flatpickr("[data-behavior='datepicker']", {
    altInput: true,
    altFormat: "m/d/Y",
    dateFormat: "Y-m-d",
  });

  $('.filter form').submit(function() {
    $(this).find('input[name], select[name]').each(function() {
      if (!$(this).val()) {
        $(this).removeAttr('name');
      }
    });
  });

  for (const el of document.querySelectorAll('[data-hotkey]')) {
    install(el)
  }
})

document.addEventListener("turbolinks:before-cache", function() {
  $(".flatpickr-input").flatpickr('destroy');
});

const images = require.context('../images', true)
